import moment from "moment"
import { MODULE_CONFIG } from "../../config/setup"

const states = {
  chatlog_details: {
    //------------------- MESSAGE STATISTICS --------------------
    message_time_series: [], //mts = message time series
    mts_filters: {
      startDate: moment(),
      endDate: moment(),
      platform: "all",
      granularity: "hour"
    },
    mts_scale: {
      count: { min: 0 },
      timestamp: {
        type: "time",
        tickInterval: 60 * 60 * 1000 * 4,
        mask: "DD MMM HH:mm",
        range: [0, 1]
      }
    },
    mts_loading: false,
    mts_load_error: false,
    //------------------- TRAIN YOUR BOT --------------------
    training_messages: [], //tm = training messages
    tm_filters: {
      startDate: moment().subtract(1, "days"),
      endDate: moment(),
      searchKey: "",
      isHandled: false,
      isTrained: false,
      limit: MODULE_CONFIG.ANALYTICS.CHATLOGS_ANALYTICS.TRAINING_MSGS_PER_PAGE_TABLE_DATA,
      skip: 0,
    },
    tm_current_page: 1,
    tm_load_more: false,
    tm_loading: false,
    tm_load_error: false,
    //---------------------------------------------------
  }
}

export default states
