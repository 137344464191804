import moment from 'moment'

const states = {
  event_details: {
    events: [],
    events_loading: false,
    events_load_error: false,
    histogram: [],
    histogram_filters: {
      startDate: moment(new Date(), "DD-MM-YYYY").subtract(1, 'days'),
      endDate: moment(new Date(), "DD-MM-YYYY")
    }
  }
}

export default states
