import moment from 'moment'

const states = {
  conversion_details: {
    filters: {
      startDate: moment(new Date(), "DD-MM-YYYY").subtract(7, 'days'),
      endDate: moment(new Date(), "DD-MM-YYYY"),
    },
    funnel_data: [],
    top_conversion_drivers: [],
    funnel_data_loading: false,
    funnel_data_load_error: false,
  }
}

export default states