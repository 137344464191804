const states = {
  // bh=broardcast_history
  broadcast_history_details: {
    bh_loading: false,
    bh_load_error: false,
    bh_list: [],
    bh_sort_order: null,
    bh_sort_field: null,
    bh_search_key: "",
    bh_current_page: 1,
    bh_page_size: 10,
    bh_total: 0,
    bh_initial_load: true,
  },
}
export default states
