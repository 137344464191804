import { message, notification } from "antd"

import * as API from "./api"
import { RESET_REDUX_STATE, UPDATE_ADMIN_STATE, UPDATE_USER_INFO } from "./actiontypes"
import { fetchDataAndProceed, setDataInLocalStorage } from "../../utils/dataUtility"
import { MODULE_MESSAGE, BRAND_INFO } from "../../config/setup"
import { LOCAL_STORAGE, METHOD_TYPES } from "../../constants/common"
import { isEmptyObject, reloadPage, log } from "../../utils/common"

export const resetReduxState = () => {
  return {
    type: RESET_REDUX_STATE
  }
}

export const updateAdminState = payload => {
  return {
    type: UPDATE_ADMIN_STATE,
    payload
  }
}

// ----------------------------- API CALLS --------------------------------

export const loginUser = (payload, callback) => {
  return dispatch => {
    dispatch(updateAdminState({ auth_loading: true }))
    fetchDataAndProceed({
      url: API.loginUser,
      method: METHOD_TYPES.POST,
      data: payload,
      loader: false
    }, (err, res) => {
      dispatch(updateAdminState({ auth_loading: false }))
      if (!err && res && res.data) {
        const ori_admin_data = {
          nlpServiceDetails: res.data.nlpServiceDetails,
          allowed_channels: res.data.allowedChannelsDetails ? res.data.allowedChannelsDetails : [],
          brandInfo: res.data.brandInfo,
          admin_id: res.data.adminId,
          token: res.data.token,
          user_info: res.data.user,
          _id: res.data._id,
        }
        setDataInLocalStorage(LOCAL_STORAGE.ORI_ADMIN, ori_admin_data)
        // ori_admin_data.auth_loading = false
        const bifurcation_data = {
          selected: res.data.selectedBifurcation || {},
          filters: res.data.bifurcationFilters || []
        }
        if (bifurcation_data.filters.length > 0 && isEmptyObject(bifurcation_data.selected)) {
          bifurcation_data.filters.forEach(item => {
            if (item.defaultValue && item.valueKey && item.options) {
              const defaultSelectedOption = item.options.find(opt => opt.value === item.defaultValue)
              if (defaultSelectedOption)
                bifurcation_data.selected[item.valueKey] = {
                  value: defaultSelectedOption.value,
                  meta: defaultSelectedOption.meta
                }
            }
          })
        }
        setDataInLocalStorage(LOCAL_STORAGE.BIFURCATION_DATA, bifurcation_data)
        dispatch(updateAdminState({ ...ori_admin_data, bifurcation_data }))
        const successMessage = res.data.user ? `Hi ${res.data.user.firstName}, Welcome to ${BRAND_INFO.BRAND_NAME} dashboard` : `Welcome to ${BRAND_INFO.BRAND_NAME} dashboard`
        message.success(successMessage)
      }
      callback(err, res)
    })
  }
}


export const forgotPassword = (payload, callback) => {
  return dispatch => {
    dispatch(updateAdminState({ auth_loading: true }))
    fetchDataAndProceed({
      url: API.forgotPassword,
      method: METHOD_TYPES.POST,
      data: payload,
      loader: false
    }, (error, response) => {
      dispatch(updateAdminState({ auth_loading: false }))
      if (!error && response) {
        callback()
        notification.success({
          message: MODULE_MESSAGE.AUTH.FORGOT_PASSWORD.request_success_title,
          description: MODULE_MESSAGE.AUTH.FORGOT_PASSWORD.request_success_description
        })
      }
    })
  }
}

export const verifyToken = payload => {
  return dispatch => {
    dispatch(updateAdminState({ token_verifying: true, token_verified: false, }))
    fetchDataAndProceed({
      url: API.verifyToken,
      method: METHOD_TYPES.POST,
      data: payload,
      loader: false
    }, (error, response) => {
      if (!error && response && response.data && response.data.user && response.data.user.length > 0) {
        dispatch(updateAdminState({
          user_info: response.data.user[0],
          token_verifying: false,
          token_verified: true,
        }))
      } else
        dispatch(updateAdminState({ token_verifying: false, token_verified: false, }))
    })
  }
}

export const resetPassword = (payload, callback) => {
  return dispatch => {
    dispatch(updateAdminState({ auth_loading: true }))
    fetchDataAndProceed({
      url: API.resetPassword,
      method: METHOD_TYPES.POST,
      data: payload,
      loader: false
    }, (error, response) => {
      dispatch(updateAdminState({ auth_loading: false }))
      if (!error && response) {
        callback()
        message.success("your password has been successfully updated")
      }
    })
  }
}

export const activateUser = (payload, callback) => {
  return dispatch => {
    dispatch(updateAdminState({ auth_loading: true }))
    fetchDataAndProceed({
      url: API.activateUser,
      method: METHOD_TYPES.POST,
      data: payload,
      loader: false
    }, (error, response) => {
      dispatch(updateAdminState({ auth_loading: false }))
      if (!error && response) {
        callback()
        message.success("your account has been successfully activated")
      }
    })
  }
}

export const resendActivationLink = (payload, callback) => {
  return dispatch => {
    dispatch(updateAdminState({ auth_loading: true }))
    fetchDataAndProceed({
      url: API.resendActivationLink,
      method: METHOD_TYPES.POST,
      data: payload,
      loader: false
    }, (error, response) => {
      dispatch(updateAdminState({ auth_loading: false }))
      callback()
      if (!error && response) {
        notification.success({
          message: MODULE_MESSAGE.AUTH.ACTIVATE_USER.request_success_title,
          description: MODULE_MESSAGE.AUTH.ACTIVATE_USER.request_success_description
        })
      }
    })
  }
}

export const logOutUser = (payload, callback) => {
  return dispatch => {
    fetchDataAndProceed({
      url: API.logOutUser,
      method: METHOD_TYPES.POST,
      data: payload,
      loader_text: "Logging out"
    }, error => {
      if (!error) {
        dispatch(resetReduxState())
        message.success("You have successfully logged out.")
        callback()
      }
    })
  }
}

export const updateBifurcation = (data, bifurcation) => dispatch => {
  fetchDataAndProceed({
    url: API.updateBifurcation,
    method: METHOD_TYPES.POST,
    data
  }, (err, res) => {
    log("updateBifurcation payload and res", data, res)
    if (!err && res && res.data && res.data.adminPsid === data.adminPsid && res.data.selectedBifurcation) {
      const bifurcation_data = {
        ...bifurcation,
        selected: res.data.selectedBifurcation,
      }
      setDataInLocalStorage(LOCAL_STORAGE.BIFURCATION_DATA, bifurcation_data)
      dispatch(updateAdminState({ bifurcation_data }))
      message.success(res.message ? res.message : "Bifurcation is successfully updated")
      .then(() => message.loading("Dashboard will reload soon"))
      .then(reloadPage)
    }
  })
}

export const updateUserProfile = payload => {
  return dispatch => {
    fetchDataAndProceed({
      url: API.updateUserProfile,
      method: METHOD_TYPES.PATCH,
      data: payload,
      loader_text: "Profile updating"
    }, (error, response) => {
      if (!error && response && response.data && response.data.user && response.data.user.length > 0) {
        dispatch({
          type: UPDATE_USER_INFO,
          payload: response.data.user[0]
        })
        message.success("profile details has been successfully updated")
      }
    })
  }
}
