import moment from "moment"

const states = {
  apilogs_details: {
    apilogs: [],
    has_more: false,
    selected_filters: {
      isSuccess: true,
      statusCode: 200,
      sessionId: "",
      startDate: moment().subtract(1, "days"),
      endDate: moment()
    },
    selected_api: {
      id: "",
      response_data: null
    }
  }
}

export default states
