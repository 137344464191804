import states from "./states"
import { BH_UPDATE_STATE } from "./actiontypes"

const broadcast_history_details = (
  state = states.broadcast_history_details,
  action
) => {
  switch (action.type) {
    case BH_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default broadcast_history_details
