import states from "./states"
import {
  TM_UPDATE_STATE,
  TM_UPDATE_FORM_DATA,
  TM_MORE_LOADED,
  TM_UPDATE_FORM_DATA_MESSAGE_DETAILS,
} from "./actiontypes"

import { MODULE_CONFIG } from "../../config/setup"

const template_master_details = (
  state = states.template_master_details,
  action
) => {
  switch (action.type) {
    case TM_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case TM_UPDATE_FORM_DATA: {
      let tm_selected_data = { ...state.tm_selected_data }
      tm_selected_data[action.payload.key] = action.payload.value
      return {
        ...state,
        tm_selected_data,
      }
    }
    case TM_MORE_LOADED:
      return {
        ...state,
        template_master: [...state.template_master, ...action.payload],
        tm_has_more:
          action.payload.length ===
          MODULE_CONFIG.BOT_BUILDER.WF_RECORDS_PER_PAGE_DATA,
      }
    //   case TM_UPDATE_FORM_DATA_MESSAGE_DETAILS: {
    //     let tm_selected_data = { ...state.tm_selected_data.messageDetail }
    //     let selectedLan = {...state.tm_selected_data.selectedLan}
    //      let index= selectedLan.indexOf(tm_selected_data[action.payload.key])
    //      if(index === -1){
    //       tm_selected_data[action.payload.key] ={
    //         "components":[
    //          {"type": action.payload.type,
    //           "text": action.payload.text,
    //          }
    //         ]
    //       }}
    //       else{

    //       }
    //     return {
    //       ...state,
    //       tm_selected_data
    //     }
    // }

    default:
      return state
  }
}

export default template_master_details
