import React, { Component } from "react"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/browser"

import { reloadPage, log } from "../../../data/utils/common"
import { checkInternetConnection } from "../../../data/utils/dataUtility"
import { IMG_ERROR } from "../../../data/assets"

import ShowMessage from "../ShowMessage"

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    log("Error boundary ", error)
    log("errorInfo", errorInfo)
    checkInternetConnection()
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
    this.setState({ hasError: true }, () => {
      if (this.props.onErrorCallback)
        this.props.onErrorCallback()
    })
  }

  render() {
    const { sm, children, sm_props } = this.props
    const { hasError } = this.state
    if (!hasError)
      return children
    else if (sm)
      return (
        <ShowMessage
          img_src={IMG_ERROR}
          title="Error occured"
          message="Something went wrong while loading the data. it might be due to a slow internet connection please reload the page"
          height={140}
          width={140}
          btn_text="Reload"
          btn_props={{
            onClick: reloadPage
          }}
          {...sm_props}
        />
      )
    return null
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  sm: PropTypes.bool,
  sm_props: PropTypes.object,
}

ErrorBoundary.defaultProps = {
  sm: false,
  sm_props: {}
}
