import states from "./states"
import { FB_UPDATE_STATE, FB_UPDATE_MESSAGE } from "./actiontypes"
import { GLOBAL_UPDATE_MESSAGE } from "../page_details/actiontypes"
import { findLastIndex } from "../../utils/common"

const feedback_details = (state = states.feedback_details, action) => {
  switch (action.type) {
    case FB_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }

    case GLOBAL_UPDATE_MESSAGE:
    case FB_UPDATE_MESSAGE: {
      if (action.payload.psid && action.payload.chatlogId && action.payload.changedValue && state.selected_feedback && state.selected_feedback.psid === action.payload.psid) {
        let index = -1
        index = findLastIndex(state.messages, msg => msg.chatlogId === action.payload.chatlogId)
        if (index >= 0)
          return {
            ...state,
            messages: [
              ...state.messages.slice(0, index),
              {
                ...state.messages[index],
                ...action.payload.changedValue
              },
              ...state.messages.slice(index + 1)
            ]
          }
        return state
      }
      return state
    }

    default:
      return state
  }
}

export default feedback_details
