import moment from "moment"

const states = {
  session_flow_details: {
    session_flow: [],
    toggle_startover: false,
    isGroupLoading: false,
    session_flow_loading: false,
    session_flow_load_error: false,
    session_flow_direction: true,
    session_flow_levels: 1,
    selected_node: { id: 0, name: "Session Start" },
    session_flow_default_filters: {},
    selectedGroup: {},
    viewList: [],
    selected_view: {},
    filter: [],
    groupList: [],
    eventList: [],
    viewAction: {},
    session_flow_filters: {
      startDate: moment(new Date(), "DD-MM-YYYY").subtract(10, "days"),
      endDate: moment(new Date(), "DD-MM-YYYY"),
    },
  },
}

export default states
