import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button } from "antd"

const ShowMessage = ({
  className,
  style,
  message,
  img_src,
  title,
  btn_text,
  btn_props,
  height,
  width,
  is_internet_connected,
}) => {
  const checkInternetConnectAndRenderErrorMessage = () => {
    if (is_internet_connected) {
      return (
        <Fragment>
          <div
            className="ori-bg-no-repeat ori-bg-size-contain ori-bg-position-center ori-b-mrgn-10"
            style={{
              height: `${height}px`,
              width: `${width}px`,
              backgroundImage: `url(${img_src})`,
            }}
          />
          {title && (
            <p className="ori-font-lg ori-font-bold ori-capitalize-first ori-text-center">
              {title}
            </p>
          )}
          {message && (
            <p className="ori-capitalize-first ori-text-center">{message}</p>
          )}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <div
          className="ori-bg-no-repeat ori-bg-size-contain ori-bg-position-center ori-b-mrgn-10"
          style={{
            height: "70px",
            width: "70px",
            backgroundImage: `url(${img_src})`,
          }}
        />
        <p className="ori-font-md ori-font-bold ori-text-center">No Internet</p>
        <p className="ori-text-center">
          internet connection has been lost please check your internet
          connection.
        </p>
      </Fragment>
    )
  }

  return (
    <div
      className={`ori-animated ori-fade-in ori-full-parent-height ori-pad-20 ori-flex-column ori-flex-center ${className}`}
      style={style}
    >
      {checkInternetConnectAndRenderErrorMessage()}
      {btn_props && (
        <div className="ori-flex-row ori-flex-jc ori-full-width ori-tb-pad-10">
          <Button {...btn_props}>{btn_text}</Button>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    is_internet_connected: state.page_details.is_internet_connected,
  }
}

ShowMessage.propTypes = {
  is_internet_connected: PropTypes.bool,
  message: PropTypes.string,
  img_src: PropTypes.string,
  title: PropTypes.string,
  btn_text: PropTypes.string,
  btn_props: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
}

ShowMessage.defaultProps = {
  btn_text: "Go Back",
  height: 70,
  width: 70,
  style: {},
  className: "",
}

export default connect(mapStateToProps)(ShowMessage)
