import moment from 'moment'

const states = {
  usage_details: {
    chat_session_time_series: [],
    chat_session_loading: false,
    chat_session_load_error: false,
    chat_session_granularity_filter: 'day',
    chat_session_scale: {
      count: {
        min: 0
      },
      timestamp: {
        type: 'time',
        tickInterval: 60 * 60 * 1000 * 24 * 2, // 2 day
        mask: 'DD MMM'
      }
    },
    user_engagement_stats: [],
    user_stats_loading: false,
    user_stats_load_error: false,
    user_retention_cohorts_columns: [],
    user_retention_cohorts_data: [],
    retention_granularity_filter: 'day',
    user_retention_loading: false,
    user_retention_load_error: false,
    filter_date_range: [moment(new Date(), "DD-MM-YYYY").subtract(1, 'days'), moment(new Date(), "DD-MM-YYYY")],
    filter_platform: "all"
  }
}

export default states