import states from "./states"
import { MODULE_CONFIG } from "../../config/setup"
import { findLastIndex } from "../../utils/common"
import { GLOBAL_UPDATE_NLP_SNAPSHOT_IN_MESSAGE } from "../nlp_details/actiontypes"
import {
  GLOBAL_UPDATE_MESSAGE,
  GLOBAL_UPDATE_CHATLIST_ITEM,
  GLOBAL_UPDATE_SELECTED_CHAT,
  // GLOBAL_UPDATE_PROFILE_FORM_LIST,
} from "../page_details/actiontypes"
import {
  HST_UPDATE_STATE,
  CHATLOGS_LOADED,
  MORE_CHATLOGS_LOADED,
  HST_UPDATE_SELECTED_CHAT,
  UPDATE_SELCECTED_CHAT_MESSAGE,
  HST_UPDATE_CHATLIST_ITEM,
} from "./actiontypes"

const history_details = (state = states.history_details, action) => {
  switch (action.type) {
    case HST_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case CHATLOGS_LOADED: {
      let index = -1
      if (
        state.selected_chat.chat_item &&
        state.selected_chat.chat_item.sessionId
      ) {
        index = action.payload.chatSessions.findIndex(
          item => item.sessionId === state.selected_chat.chat_item.sessionId
        )
      }
      return {
        ...state,
        chat_list: action.payload.chatSessions,
        load_more:
          action.payload.chatSessions.length ===
          MODULE_CONFIG.HISTORY.LOAD_MORE_CHATLOGS_COUNT,
        total_count: action.payload.totalCount ? action.payload.totalCount : 0,
        selected_chat:
          index === -1
            ? states.history_details.selected_chat
            : { ...state.selected_chat },
      }
    }

    case MORE_CHATLOGS_LOADED: {
      return {
        ...state,
        chat_list: [...state.chat_list, ...action.payload.chatSessions],
        load_more:
          action.payload.chatSessions.length ===
          MODULE_CONFIG.HISTORY.LOAD_MORE_CHATLOGS_COUNT,
      }
    }

    case GLOBAL_UPDATE_SELECTED_CHAT:
    case HST_UPDATE_SELECTED_CHAT: {
      if (
        state.selected_chat.chat_item &&
        state.selected_chat.chat_item.sessionId === action.payload.sessionId
      ) {
        if (action.payload.key)
          return {
            ...state,
            selected_chat: {
              ...state.selected_chat,
              [action.payload.key]: {
                ...state.selected_chat[action.payload.key],
                ...action.payload.value,
              },
            },
          }
        else
          return {
            ...state,
            selected_chat: {
              ...state.selected_chat,
              ...action.payload.changedValue,
            },
          }
      } else return state
    }

    case GLOBAL_UPDATE_CHATLIST_ITEM:
    case HST_UPDATE_CHATLIST_ITEM: {
      let index = -1
      index = state.chat_list.findIndex(
        chat_item => chat_item.sessionId === action.payload.sessionId
      )
      if (index !== -1) {
        if (
          state.selected_chat.chat_item &&
          state.selected_chat.chat_item.sessionId === action.payload.sessionId
        )
          return {
            ...state,
            chat_list: [
              ...state.chat_list.slice(0, index),
              {
                ...state.chat_list[index],
                ...action.payload.changedValue,
              },
              ...state.chat_list.slice(index + 1),
            ],
            selected_chat: {
              ...state.selected_chat,
              chat_item: {
                ...state.selected_chat.chat_item,
                ...action.payload.changedValue,
              },
            },
          }
        else
          return {
            ...state,
            chat_list: [
              ...state.chat_list.slice(0, index),
              {
                ...state.chat_list[index],
                ...action.payload.changedValue,
              },
              ...state.chat_list.slice(index + 1),
            ],
          }
      } else return state
    }

    case GLOBAL_UPDATE_MESSAGE:
    case UPDATE_SELCECTED_CHAT_MESSAGE: {
      if (
        action.payload.psid &&
        action.payload.chatlogId &&
        action.payload.changedValue &&
        state.selected_chat.chat_item &&
        state.selected_chat.chat_item.psid === action.payload.psid
      ) {
        let index = -1
        index = findLastIndex(
          state.selected_chat.messages,
          message => message.chatlogId === action.payload.chatlogId
        )
        if (index >= 0) {
          return {
            ...state,
            selected_chat: {
              ...state.selected_chat,
              messages: [
                ...state.selected_chat.messages.slice(0, index),
                {
                  ...state.selected_chat.messages[index],
                  ...action.payload.changedValue,
                },
                ...state.selected_chat.messages.slice(index + 1),
              ],
            },
          }
        } else return state
      } else return state
    }

    // case GLOBAL_UPDATE_PROFILE_FORM_LIST: {
    //   if (
    //     state.selected_chat.chat_item &&
    //     state.selected_chat.chat_item.sessionId &&
    //     state.selected_chat.chat_item.sessionId === action.payload.sessionId &&
    //     state.selected_chat.profile.userProfileWidget
    //   ) {
    //     const index = state.selected_chat.profile.userProfileWidget.findIndex(
    //       form => form.id === action.payload.updatedForm.id
    //     )
    //     if (index >= 0)
    //       return {
    //         ...state,
    //         selected_chat: {
    //           ...state.selected_chat,
    //           profile: {
    //             ...state.selected_chat.profile,
    //             userProfileWidget: [
    //               ...state.selected_chat.profile.userProfileWidget.slice(0, index),
    //               action.payload.updatedForm,
    //               ...state.selected_chat.profile.userProfileWidget.slice(index + 1),
    //             ],
    //           },
    //         },
    //       }
    //     return state
    //   }
    //   return state
    // }

    case GLOBAL_UPDATE_NLP_SNAPSHOT_IN_MESSAGE: {
      if (state.selected_chat.chat_item) {
        const index = state.selected_chat.messages.findIndex(
          message => message.chatlogId === action.payload.chatlogId
        )
        if (index >= 0)
          return {
            ...state,
            selected_chat: {
              ...state.selected_chat,
              messages: [
                ...state.selected_chat.messages.slice(0, index),
                {
                  ...state.selected_chat.messages[index],
                  NLPSnapshot: action.payload.NLPSnapshot,
                  prevNLPSnapshot: state.selected_chat.messages[index]
                    .prevNLPSnapshot
                    ? state.selected_chat.messages[index].prevNLPSnapshot
                    : state.selected_chat.messages[index].NLPSnapshot,
                },
                ...state.selected_chat.messages.slice(index + 1),
              ],
            },
          }
        return state
      }
      return state
    }

    default:
      return state
  }
}

export default history_details
