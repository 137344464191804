import states from "./states"
import { ACTION_UPDATE_STATE, UPDATE_ACTION_RECORD } from "./actiontypes"

const action_details = (state = states.action_details, action) => {
  switch (action.type) {
    case ACTION_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_ACTION_RECORD: {
      const index = state.records.findIndex(
        item => item.actionId === action.payload.actionId
      )
      if (index >= 0) {
        return {
          ...state,
          records: [
            ...state.records.slice(0, index),
            { ...action.payload },
            ...state.records.slice(index + 1),
          ],
        }
      }
      return state
    }

    default:
      return state
  }
}

export default action_details
