import * as actionTypes from './actiontypes'
import states from './states'

const agent_details = (state = states.agent_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AGENT_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.AGENT_DELETED: {
      let index = -1;
      index = state.agents.findIndex(agent => agent._id === action.payload[0]._id);
      return {
        ...state,
        agents: index !== -1 ?
          [
            ...state.agents.slice(0, index),
            action.payload[0],
            ...state.agents.slice(index + 1)
          ] :
          [...state.agents]
      }
    }

    case actionTypes.NEW_AGENTS_ADDED:
      return {
        ...state,
        agents: [...action.payload, ...state.agents],
      }

    default:
      return state
  }
}

export default agent_details