import moment from "moment"
import { jsPDF } from "jspdf"
import { message } from "antd"
import { MESSAGE_TYPES } from "../constants/chats"
import { log } from "./common"

const removeEmojis = text =>
  text.replace(
    /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
    ""
  )

const increment = ({ doc, line }) => {
  line = line + 5
  if (line >= 280) {
    doc.addPage()
    line = 25 // reset to default
  }
  return line
}

const addToPDF = ({ doc, line, text }) => {
  let strArr = doc.splitTextToSize(text, 180)
  strArr.forEach(txt => {
    doc.text(txt, 10, line)
    line = increment({ doc, line })
  })
  return line
}

const btnDisplay = ({ doc, line, btn }) => {
  if (btn.text) {
    doc.text(`• ${btn.text}`, 20, line)
    line = increment({ doc, line })
  }
  return line
}

export const downloadChatLogsPDF = ({ fileName, messages }) => {
  try {
    let line = 25
    let chats = ""
    const doc = new jsPDF()
    doc.setFontSize(8)

    messages.forEach(msg => {
      const rowHeader = `${moment(msg.timestamp).format(
        "DD MMM YYYY hh:mm A"
      )} [${
        msg.senderInfo && msg.senderInfo.pseudoName
          ? msg.senderInfo.pseudoName
          : msg.sender
      }]:-` // !! pseudoName or firstName?
      const sender =
        msg.senderInfo && msg.senderInfo.pseudoName
          ? msg.senderInfo.pseudoName
          : msg.sender

      const date = moment(msg.timestamp).format("DD MMM YYYY")
      const time = moment(msg.timestamp).format("hh:mm A")

      const title =
        typeof msg.payload.title === "string" ? msg.payload.title : ""
      const subTitle =
        typeof msg.payload.subtitle === "string"
          ? removeEmojis(msg.payload.subtitle)
          : ""
      const imageUrl =
        typeof msg.payload.imageUrl === "string" ? msg.payload.imageUrl : ""
      const videoUrl =
        typeof msg.payload.url === "string" ? msg.payload.url : ""

      let text = ""
      let stringArray = null

      switch (msg.type) {
        case MESSAGE_TYPES.TEXT: {
          text = `${rowHeader} ${removeEmojis(msg.payload.text)}`
          line = addToPDF({ doc, line, text })
          if (msg.containsHTML) {
            const content = msg.payload.text
            chats = chats + `<div>${content}</div>`
          }
          break
        }
        case MESSAGE_TYPES.TEXT_WITH_BUTTONS: {
          text = `${date} ${time} [${sender}]:- ${subTitle} `
          line = addToPDF({ doc, line, text })
          if (msg.payload.buttons) {
            doc.text("Select options:", 15, line)
            line = line + 5
            msg.payload.buttons.forEach(btn => {
              line = btnDisplay({ btn, line, doc })
            })
          }
          break
        }
        case MESSAGE_TYPES.IMAGE_WITH_BUTTONS: {
          doc.text(`${date} ${time} [${sender}]:- `, 10, line)
          line = line + 5
          doc.setTextColor(0, 0, 255)
          doc.textWithLink(`Image Link`, 40, line, { url: imageUrl })
          doc.setTextColor(0, 0, 0)
          if (msg.payload.buttons) {
            doc.text("Select options:", 15, line)
            line = line + 5
            msg.payload.buttons.forEach(btn => {
              line = btnDisplay({ btn, line, doc })
            })
          }
          break
        }
        case MESSAGE_TYPES.CAROUSEL: {
          text = `${date} ${time} [${sender}]:- ${title} ${subTitle} `
          line = addToPDF({ doc, line, text })
          if (msg.payload.options) {
            let numFlag = false
            msg.payload.options.forEach((option, index) => {
              const num = index + 1
              if (option.title) {
                stringArray = doc.splitTextToSize(
                  `${num}. ${option.title}`,
                  180
                )
                numFlag = true
                stringArray.forEach(text => {
                  doc.text(text, 15, line)
                  line = increment({ line, doc })
                })
              }
              if (option.subtitle) {
                const docText = numFlag
                  ? `${option.subtitle}`
                  : `${num}. ${option.subtitle}`
                doc.text(docText, numFlag ? 20 : 15, line)
                numFlag = true
                line = increment({ line, doc })
              }
              if (option.mediaUrl) {
                const docText = numFlag ? "Image Link" : `${num}. Image Link`
                doc.text(docText, numFlag ? 21 : 15, line)
                numFlag = true
                doc.setTextColor(0, 0, 255)
                doc.textWithLink("OPEN", 40, line, { url: option.mediaUrl })
                doc.setTextColor(0, 0, 0)
                line = increment({ line, doc })
              }
              if (option.buttons) {
                doc.text("Select options:", 15, line)
                line = line + 5
                option.buttons.forEach(btn => {
                  line = btnDisplay({ btn, line, doc })
                })
              }
            })
          }
          if (msg.payload.buttons) {
            doc.text("Select options:", 15, line)
            line = line + 5
            msg.payload.buttons.forEach(btn => {
              line = btnDisplay({ btn, line, doc })
            })
          }
          break
        }
        case MESSAGE_TYPES.VIDEO: {
          text = `${date} ${time}:- ${sender}:${title} ${subTitle}`
          line = addToPDF({ doc, line, text })
          doc.text("Video Link", 10, line)
          doc.setTextColor(0, 0, 255)
          doc.textWithLink(`OPEN`, 40, line, { url: videoUrl })
          doc.setTextColor(0, 0, 0)
          line = increment({ line, doc })
          break
        }
        case MESSAGE_TYPES.CHECKBOX_WITH_MEDIA: {
          text = `${date} ${time}:- ${sender}:${title} ${subTitle} `
          if (msg.payload.options) {
            msg.payload.options.forEach(option => {
              if (option.label) {
                doc.text(`LABEL: ${option.label}`, 10, line)
                line = increment({ line, doc })
              }
            })
          }
          if (msg.payload.buttons) {
            doc.text("Select options:", 15, line)
            line = line + 5
            msg.payload.buttons.forEach(btn => {
              line = btnDisplay({ btn, line, doc })
            })
          }
          doc.text("Image Link", 10, line)
          doc.setTextColor(0, 0, 255)
          doc.textWithLink(`OPEN`, 40, line, { url: imageUrl })
          doc.setTextColor(0, 0, 0)
          break
        }
        case MESSAGE_TYPES.CUSTOM_MSG: {
          // chats += `${date} ${time}:- ${sender}:${t1} ${subTitle} ${btnarr} \r\n`
          text = `${date} ${time}:- ${sender}:${title} ${subTitle}`
          line = addToPDF({ doc, line, text })
          msg.payload.buttons.forEach(btn => {
            line = btnDisplay({ btn, line, doc })
          })
          break
        }
        default: {
          log(
            "%cUnknown Message Type!",
            "background: red; color: pink;",
            msg.type
          )
          break
        }
      }
    })
    doc.save(fileName)
  } catch (ex) {
    message.error(
      "Something Failed while downloading the chat logs (pdf), Please try again later!"
    )
    // throw new Error("ChatLogs Pdf generation has been failed")
  }
}
