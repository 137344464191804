import { createStore, compose, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunkMiddleware from "redux-thunk"
import * as Sentry from "@sentry/browser"

import dashboardMiddleware from "../redux/dashboard_details/middleware"
import chatsMiddleware from "../redux/chats_details/middleware"
import agentMonitoringMiddleware from "../redux/agent_monitoring_details/middleware"
import dialogueMiddleware from "../redux/dialogue_details/middleware"

import rootReducer from "../redux"

const round = number => Math.round(number * 100) / 100

const loggerMiddleware = store => next => action => {
  console.group(action.type)
  console.info("dispatching", action)
  let result = next(action)
  console.log("next state", store.getState())
  console.groupEnd()
  return result
}

const crashReporterMiddleware = store => next => action => {
  try {
    return next(action)
  } catch (error) {
    console.error("Caught an exception!", error)
    Sentry.withScope(scope => {
      const user_info = store.getState().admin_details.user_info
      if (user_info.email)
        scope.setUser({
          email: user_info.email,
          username: `${user_info.firstName} ${user_info.lastName}`,
        })
      scope.setExtras({ action, state: store.getState() })
      Sentry.captureException(error)
    })
    throw error
  }
}

const monitorReducerEnhancer =
  createStore => (reducer, initialState, enhancer) => {
    const monitoredReducer = (state, action) => {
      const start = performance.now()
      const newState = reducer(state, action)
      const end = performance.now()
      const diff = round(end - start)
      console.log("reducer process time:", diff)
      return newState
    }
    return createStore(monitoredReducer, initialState, enhancer)
  }

const configureStoreDev = initialState => {
  const middlewares = [
    loggerMiddleware,
    crashReporterMiddleware,
    chatsMiddleware,
    dashboardMiddleware,
    agentMonitoringMiddleware,
    dialogueMiddleware,
    thunkMiddleware,
  ]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer, monitorReducerEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)
  const store = createStore(rootReducer, initialState, composedEnhancers)
  if (module.hot)
    module.hot.accept("../redux", () => store.replaceReducer(rootReducer))
  return store
}

const configureStoreProd = initialState => {
  const middlewares = [
    crashReporterMiddleware,
    chatsMiddleware,
    dashboardMiddleware,
    agentMonitoringMiddleware,
    dialogueMiddleware,
    thunkMiddleware,
  ]
  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  )
}

const configureStore =
  process.env.NODE_ENV === "production" ? configureStoreProd : configureStoreDev

export default configureStore
