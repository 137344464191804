import states from "./states"
import {
  CUST_UPDATE_STATE,
  CUST_UPDATE_CUSTOMER_PROFILE,
  CUST_UPDATE_SELECTED_CHATLOG,
  CUST_MORE_CHATLOGS_LOADED,
  CUST_UPDATE_SELCECTED_CHAT_MESSAGE,
  CUST_UPDATE_CHATLIST_ITEM,
} from "./actiontypes"
import { findLastIndex } from "../../utils/common"
import {
  GLOBAL_UPDATE_MESSAGE,
  GLOBAL_UPDATE_CHATLIST_ITEM,
  GLOBAL_UPDATE_SELECTED_CHAT,
} from "../page_details/actiontypes"
import { MODULE_CONFIG } from "../../config/setup"

const customers_details = (state = states.customers_details, action) => {
  switch (action.type) {
    case CUST_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case CUST_UPDATE_CUSTOMER_PROFILE:
      return {
        ...state,
        customer_profile: {
          ...state.customer_profile,
          ...action.payload,
        },
      }

    case GLOBAL_UPDATE_SELECTED_CHAT:
    case CUST_UPDATE_SELECTED_CHATLOG:
      if (
        state.customer_profile.selected_chat.chat_item &&
        state.customer_profile.selected_chat.chat_item.sessionId ===
          action.payload.sessionId
      )
        return {
          ...state,
          customer_profile: {
            ...state.customer_profile,
            selected_chat: {
              ...state.customer_profile.selected_chat,
              [action.payload.key]: {
                ...state.customer_profile.selected_chat[action.payload.key],
                ...action.payload.value,
              },
            },
          },
        }
      else return state

    case CUST_MORE_CHATLOGS_LOADED:
      return {
        ...state,
        customer_profile: {
          ...state.customer_profile,
          chatlogs: [
            ...state.customer_profile.chatlogs,
            ...action.payload.chatSessions,
          ],
          load_more:
            action.payload.chatSessions.length ===
            MODULE_CONFIG.CUSTOMERS.LOAD_MORE_CUSTOMER_CHATLOG_COUNT,
        },
      }

    case GLOBAL_UPDATE_CHATLIST_ITEM:
    case CUST_UPDATE_CHATLIST_ITEM: {
      let index = -1
      index = state.customer_profile.chatlogs.findIndex(
        chat_item => chat_item.sessionId === action.payload.sessionId
      )
      if (index !== -1) {
        if (
          state.customer_profile.selected_chat.chat_item &&
          state.customer_profile.selected_chat.chat_item.sessionId ===
            action.payload.sessionId
        )
          return {
            ...state,
            customer_profile: {
              ...state.customer_profile,
              chatlogs: [
                ...state.customer_profile.chatlogs.slice(0, index),
                {
                  ...state.customer_profile.chatlogs[index],
                  ...action.payload.changedValue,
                },
                ...state.customer_profile.chatlogs.slice(index + 1),
              ],
              selected_chat: {
                ...state.customer_profile.selected_chat,
                chat_item: {
                  ...state.customer_profile.selected_chat.chat_item,
                  ...action.payload.changedValue,
                },
              },
            },
          }
        else
          return {
            ...state,
            customer_profile: {
              ...state.customer_profile,
              chatlogs: [
                ...state.customer_profile.chatlogs.slice(0, index),
                {
                  ...state.customer_profile.chatlogs[index],
                  ...action.payload.changedValue,
                },
                ...state.customer_profile.chatlogs.slice(index + 1),
              ],
            },
          }
      } else return state
    }

    case GLOBAL_UPDATE_MESSAGE:
    case CUST_UPDATE_SELCECTED_CHAT_MESSAGE: {
      if (
        action.payload.psid &&
        action.payload.chatlogId &&
        action.payload.changedValue &&
        state.customer_profile.selected_chat.chat_item &&
        state.customer_profile.selected_chat.chat_item.psid ===
          action.payload.psid
      ) {
        let index = -1
        index = findLastIndex(
          state.customer_profile.selected_chat.messages,
          message => message.chatlogId === action.payload.chatlogId
        )
        if (index >= 0) {
          return {
            ...state,
            customer_profile: {
              ...state.customer_profile,
              selected_chat: {
                ...state.customer_profile.selected_chat,
                messages: [
                  ...state.customer_profile.selected_chat.messages.slice(
                    0,
                    index
                  ),
                  {
                    ...state.customer_profile.selected_chat.messages[index],
                    ...action.payload.changedValue,
                  },
                  ...state.customer_profile.selected_chat.messages.slice(
                    index + 1
                  ),
                ],
              },
            },
          }
        } else return state
      } else return state
    }

    default:
      return state
  }
}

export default customers_details
