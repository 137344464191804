import moment from "moment"

const states = {
  kpi_details: {
    stats_list: [],
    filters: [],
    selected_filters: {
      startDate: moment().subtract(1, "days"),
      endDate: moment().subtract(1, "days")
    }
  }
}

export default states
