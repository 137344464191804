import moment from "moment"

const states = {
  feedback_details: {
    feedbacks: [],
    filters: {
      startDate: moment().subtract(1, "days"),
      endDate: moment()
    },
    selected_feedback: null,
    messages: [],
    loading: false,
    load_error: false
  }
}

export default states
