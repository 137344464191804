export const LOCAL_STORAGE = {
  ORI_ADMIN: "dashboardUser",
  VERSION: "dashboardVersion",
  LIVE_CHATS_UNREAD_MESSAGE_COUNT: "liveChatsUnreadMessageCount",
  UNSEEN_LIVE_CHATS_STALL_COUNT: "unseenLiveChatStallCount",
  BIFURCATION_DATA: "bifurcationData",
}

export const METHOD_TYPES = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
}

export const ACTION_TYPES = {
  UPDATE_STATE: "UPDATE_STATE",
  LOAD_MORE_UTTERANCES: "LOAD_MORE_UTTERANCES",
}

export const CURRENCY = {
  RUPEES: "rupees",
  DOLLAR: "dollar",
}

export const FORM_ITEM_TYPE = {
  INPUT: "input",
  INPUT_NUMBER: "inputNumber",
  INPUT_STRING: "inputString",
  TEXTAREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  RADIO_GROUP_WITH_MULTI_OPTIONS: "radioGroupWithMultiOptions",
  SELECT: "select",
}

export const NLP_SERVICE_NAME = {
  LUIS: "luis",
  RASA: "rasa",
}

export const WF_DIAGRAM = {
  DEFAULT_EDGE: "defaultEdge",
  BLOCK_NODE: "blockNode", //BN
  CONDITIONAL_NODE: "conditionalNode", //CN
  ACTION_NODE: "actionNode", //AN
  TRIGGER_NODE: "triggerNode", //TN
  WORKFLOW_NODE: "workflowNode", //WN
  BN_SOURCE_HANDLE: "__BN-SH__",
  BN_TARGET_HANDLE: "__BN-TH__",
  CN_TARGET_HANDLE: "__CN-TH__",
  AN_TARGET_HANDLE: "__AN_TH__",
  AN_SOURCE_HANDLE: "__AN_SH__",
  TN_SOURCE_HANDLE: "__TN_SH__",
  WN_TARGET_HANDLE: "__WN_TH__",
}
