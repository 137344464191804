import states from './states'
import {
    UPDATE_VARIABLE_STATE,
    VARIABLE_DELETED
} from './actiontypes'

const botparams_details = (state = states.botparams_details, action) => {
    switch (action.type) {
        case UPDATE_VARIABLE_STATE: {
            return {
                ...state,
                ...action.payload
            }
        }

        case VARIABLE_DELETED: {
            const index = state.variables.findIndex(variable => variable._id === action.payload)
            return {
                ...state,
                variables: index !== -1 ? [...state.variables.slice(0, index), ...state.variables.slice(index + 1)] : [...state.variables],
            }
        }

        default:
            return state
    }
}

export default botparams_details
