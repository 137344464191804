export const SENTRY_DSN =
  "https://3029dcaada6345199d95a573127fac7c@o218879.ingest.sentry.io/1837473"
export const CLOUD_URL = "https://us-central1-oriserve-bcd30.cloudfunctions.net"
export const NETWORK_CHECK_URL = "https://www.google.com/"
export const LAMBDA_URL= process.env.REACT_APP_LAMBDA_URL
export const BASE_URL_USERS = process.env.REACT_APP_BASE_URL_USERS
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_SOCKET_URL = process.env.REACT_APP_BASE_SOCKET_URL
export const SOCKET_URLS = {
  LIVE_CONVERSATIONS_SOCKET_URL: `${BASE_SOCKET_URL}/liveConversations`,
  LIVE_CHATS_STATS_SOCKET_URL: `${BASE_SOCKET_URL}/liveChatsStats`,
  LIVE_ADMINS_SOCKET_URL: `${BASE_SOCKET_URL}/liveAdmins`,
  DIALOGUE_SOCKET_URL: `${BASE_SOCKET_URL}/liveDialogTraining`
}

