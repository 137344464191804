import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { LoadingOutlined, DisconnectOutlined } from "@ant-design/icons"

import { checkInternetConnection } from "../../../../data/utils/dataUtility"

const reconnectInitialState = {
  tryCount: 0,
  waitingTime: 5
}

const NetworkAlert = ({
  loading,
  is_internet_connected,
  screen_width
}) => {
  const [reconnect, setReconnect] = useState(reconnectInitialState)
  const timer = useRef(null)

  useEffect(() => {
    return () => {
      if (timer.current)
        clearInterval(timer.current)
    }
  }, [])

  useEffect(() => {
    if (loading && timer.current)
      clearInterval(timer.current)
    if (!loading && !is_internet_connected && navigator.onLine)
      timer.current = setInterval(() => {
        setReconnect(prevReconnect => ({
          ...prevReconnect,
          waitingTime: prevReconnect.waitingTime > 0 ? prevReconnect.waitingTime - 1 : 0
        }))
      }, 1000)
  }, [loading, is_internet_connected])

  useEffect(() => {
    if (reconnect.waitingTime === 0) {
      checkInternetConnection(() => {
        if (navigator.onLine)
          setReconnect(prevReconnect => ({
            tryCount: prevReconnect.tryCount + 1,
            waitingTime: 2 * (prevReconnect.tryCount + 1) * reconnectInitialState.waitingTime,
          }))
      })
    }
  }, [reconnect.waitingTime])

  const onClickReconnect = () => {
    checkInternetConnection(() => {
      if (navigator.onLine)
        setReconnect(reconnectInitialState)
    })
  }

  const renderWaitingTime = () => {
    if (!loading && navigator.onLine && reconnect.waitingTime) {
      if (reconnect.waitingTime < 60)
        return (
          <p>Retry after <span className="ori-font-green ori-font-bold">{reconnect.waitingTime} sec</span></p>
        )
      else if (reconnect.waitingTime < 3600) {
        const min = Math.floor(reconnect.waitingTime / 60)
        const sec = reconnect.waitingTime % 60
        return (
          <p>Retry after <span className="ori-font-green ori-font-bold">{min} min {sec} sec</span></p>
        )
      } else {
        const hour = Math.floor(reconnect.waitingTime / 3600)
        const min = Math.floor((reconnect.waitingTime - hour * 3600) / 60)
        const sec = (reconnect.waitingTime - hour * 3600 - min * 60)
        return (
          <p>Retry after <span className="ori-font-green ori-font-bold">{hour} hour {min} min {sec} sec</span></p>
        )
      }
    }
  }

  const renderLoaderAndReconnect = () => {
    if (loading)
      return <p className="ori-font-warning">Connecting ...</p>
    if (navigator.onLine)
      return <p className="ori-font-warning ori-cursor-ptr" onClick={onClickReconnect}>Reconnect</p>
  }

  return (
    <div
      className="ori-absolute ori-animated ori-fade-in-right ori-pad-10 ori-flex-row ori-bg-white ori-border-radius-base ori-zindex-99999 ori-box-shadow ori-border-light"
      style={{
        right: "15px",
        top: "75px",
        width: screen_width > 450 ? "400px" : "calc(100% - 30px)"
      }}
    >
      <div
        className="ori-font-warning ori-lr-pad-10"
        style={{ fontSize: "50px" }}
      >
        {
          loading ? <LoadingOutlined /> : <DisconnectOutlined />
        }
      </div>
      <div className="ori-l-pad-10">
        <p className="ori-font-md">
          {
            navigator.onLine ?
              "Very slow/No Connection" :
              "Device not connected"
          }
        </p>
        <p className="ori-font-light" style={{ lineHeight: "1.2" }}>
          {
            navigator.onLine ?
              "Make sure your system has an active internet connection, we are unable to fetch data" :
              "Make sure your system is connected with the internet"
          }
        </p>
        {renderWaitingTime()}
        {renderLoaderAndReconnect()}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.page_details.checking_internet,
    is_internet_connected: state.page_details.is_internet_connected,
    screen_width: state.page_details.device_data.screen_width
  }
}

NetworkAlert.propTypes = {
  loading: PropTypes.bool,
  is_internet_connected: PropTypes.bool,
  screen_width: PropTypes.number
}

export default connect(mapStateToProps)(NetworkAlert)
