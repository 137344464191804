import React from "react"
import PropTypes from "prop-types"
import { Spin } from "antd"

const SpinnerLoader = ({ text, size, container_class, loader_wrapper_class }) => {
  return (
    <div className={`ori-full-parent-height ori-flex ori-flex-center ${container_class}`}>
      <div className={`ori-lr-pad-15 ori-t-pad-15 ori-b-pad-10 ori-border-radius-base ${loader_wrapper_class}`}>
        <Spin size={size} />
        {
          text &&
          <span className="ori-l-mrgn-10 ori-font-primary ori-capitalize">{text + "..."}</span>
        }
      </div>
    </div>
  )
}

SpinnerLoader.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(["large", "small", "default"]),
  container_class: PropTypes.string,
  loader_wrapper_class: PropTypes.string
}

SpinnerLoader.defaultProps = {
  size: "default",
  container_class: "",
  loader_wrapper_class: ""
}

export default SpinnerLoader;
