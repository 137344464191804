import { message as displayMessage } from "antd"

import {
  AM_UPDATE_STATE,
  AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM,
  AM_UPDATE_AGENT_CHATS,
  AM_UPDATE_AGENT_ACTIVE_CHATS_MESSAGE,
  AM_CLOSE_CHAT_WINDOW,
} from "./actiontypes"
import {
  GLOBAL_UPDATE_MESSAGE,
  GLOBAL_UPDATE_ACTIVE_CHATS_ITEM,
} from "../page_details/actiontypes"
import states from "./states"
import { findLastIndex } from "../../utils/common"

const agent_monitoring_details = (
  state = states.agent_monitoring_details,
  action
) => {
  switch (action.type) {
    case AM_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case GLOBAL_UPDATE_ACTIVE_CHATS_ITEM:
    case AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM: {
      if (state.agent_active_chats[action.payload.id]) {
        return {
          ...state,
          agent_active_chats: {
            ...state.agent_active_chats,
            [action.payload.id]: {
              ...state.agent_active_chats[action.payload.id],
              [action.payload.key]: action.payload.replace
                ? action.payload.value
                : action.payload.is_array
                ? [
                    ...state.agent_active_chats[action.payload.id][
                      action.payload.key
                    ],
                    action.payload.value,
                  ]
                : {
                    ...state.agent_active_chats[action.payload.id][
                      action.payload.key
                    ],
                    ...action.payload.value,
                  },
            },
          },
        }
      } else return state
    }

    case AM_UPDATE_AGENT_CHATS: {
      if (
        state.selected_agent_active_chat_id &&
        action.payload[state.selected_agent_active_chat_id] === undefined
      ) {
        let agent_active_chats = { ...state.agent_active_chats }
        delete agent_active_chats[state.selected_agent_active_chat_id]
        displayMessage.warning(
          "Customer is either disconnected or force takeover by the supervisor"
        )
        return {
          ...state,
          agent_chats: action.payload,
          selected_agent_active_chat_id: null,
          agent_active_chats,
        }
      }
      return {
        ...state,
        agent_chats: action.payload,
      }
    }

    case GLOBAL_UPDATE_MESSAGE:
    case AM_UPDATE_AGENT_ACTIVE_CHATS_MESSAGE: {
      if (
        action.payload.psid &&
        action.payload.chatlogId &&
        action.payload.changedValue &&
        state.agent_active_chats[action.payload.psid]
      ) {
        let index = -1
        index = findLastIndex(
          state.agent_active_chats[action.payload.psid].messages,
          msg => msg.chatlogId === action.payload.chatlogId
        )
        if (index >= 0)
          return {
            ...state,
            agent_active_chats: {
              ...state.agent_active_chats,
              [action.payload.psid]: {
                ...state.agent_active_chats[action.payload.psid],
                messages: [
                  ...state.agent_active_chats[
                    action.payload.psid
                  ].messages.slice(0, index),
                  {
                    ...state.agent_active_chats[action.payload.psid].messages[
                      index
                    ],
                    ...action.payload.changedValue,
                  },
                  ...state.agent_active_chats[
                    action.payload.psid
                  ].messages.slice(index + 1),
                ],
              },
            },
          }
        return state
      }
      return state
    }

    case AM_CLOSE_CHAT_WINDOW: {
      let agent_active_chats = { ...state.agent_active_chats }
      if (state.selected_agent_active_chat_id)
        delete agent_active_chats[state.selected_agent_active_chat_id]
      return {
        ...state,
        selected_agent_active_chat_id: null,
        agent_active_chats,
      }
    }

    default:
      return state
  }
}

export default agent_monitoring_details
