import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import SpinnerLoader from "../SpinnerLoader"
import ErrorBoundary from "../ErrorBoundary"
import NetworkAlert from "./NetworkAlert"

const GlobalInformation = React.memo(({
  page_loading,
  page_loading_text,
  is_internet_connected
}) => {
  if (is_internet_connected && !page_loading)
    return null
  return (
    <ErrorBoundary>
      {
        !is_internet_connected &&
        <NetworkAlert />
      }
      {
        page_loading &&
        <div className="ori-absolute ori-bg-black-light ori-align-full ori-zindex-99999">
          <SpinnerLoader
            text={page_loading_text}
            loader_wrapper_class="ori-bg-white"
          />
        </div>
      }
    </ErrorBoundary>
  )
})

const mapStateToProps = state => {
  return {
    page_loading: state.page_details.page_loading,
    page_loading_text: state.page_details.page_loading_text,
    is_internet_connected: state.page_details.is_internet_connected
  }
}

GlobalInformation.propTypes = {
  page_loading: PropTypes.bool,
  page_loading_text: PropTypes.string,
  is_internet_connected: PropTypes.bool
}

export default connect(mapStateToProps)(GlobalInformation)
